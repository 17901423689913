import { defineNuxtPlugin } from '#app/nuxt'
import { LazyABContent, LazyAutoplayCreatorSample, LazyBentoGrid, LazyBentoGridItem, LazyBoxedContent, LazyBulletPointComparison, LazyCTABlock, LazyContentCarousel, LazyContentCarouselItem, LazyDesignListing, LazyDistancerWave, LazyFeatureGrid, LazyFullWidthImage, LazyFullWidthText, LazyGiftVouchers, LazyHeadline, LazyImageTextBlock, LazyImageTextCtaBlock, LazyInfoBlock, LazyKeywordCarousel, LazyLinkingHub, LazyMidsectionCTA, LazyNewsletterSignup, LazyProductCarousel, LazySeoText, LazyStandaloneHeadline, LazyTestimonialCarousel, LazyTestimonialCarouselItem, LazyTextTile, LazyTextTileCarousel, LazyTextTileRow, LazyTwoWayComparison } from '#components'
const lazyGlobalComponents = [
  ["ABContent", LazyABContent],
["AutoplayCreatorSample", LazyAutoplayCreatorSample],
["BentoGrid", LazyBentoGrid],
["BentoGridItem", LazyBentoGridItem],
["BoxedContent", LazyBoxedContent],
["BulletPointComparison", LazyBulletPointComparison],
["CTABlock", LazyCTABlock],
["ContentCarousel", LazyContentCarousel],
["ContentCarouselItem", LazyContentCarouselItem],
["DesignListing", LazyDesignListing],
["DistancerWave", LazyDistancerWave],
["FeatureGrid", LazyFeatureGrid],
["FullWidthImage", LazyFullWidthImage],
["FullWidthText", LazyFullWidthText],
["GiftVouchers", LazyGiftVouchers],
["Headline", LazyHeadline],
["ImageTextBlock", LazyImageTextBlock],
["ImageTextCtaBlock", LazyImageTextCtaBlock],
["InfoBlock", LazyInfoBlock],
["KeywordCarousel", LazyKeywordCarousel],
["LinkingHub", LazyLinkingHub],
["MidsectionCTA", LazyMidsectionCTA],
["NewsletterSignup", LazyNewsletterSignup],
["ProductCarousel", LazyProductCarousel],
["SeoText", LazySeoText],
["StandaloneHeadline", LazyStandaloneHeadline],
["TestimonialCarousel", LazyTestimonialCarousel],
["TestimonialCarouselItem", LazyTestimonialCarouselItem],
["TextTile", LazyTextTile],
["TextTileCarousel", LazyTextTileCarousel],
["TextTileRow", LazyTextTileRow],
["TwoWayComparison", LazyTwoWayComparison],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
